import React from 'react'
import { useNavigate } from 'react-router-dom'
// styles
import styles from './index.module.scss'
const { siteName } =
  require(`@/themes/${process.env.NEXT_PUBLIC_PLATFORM}/config`).default
/**
 * 400 页面
 */
const NotFound = () => {
  const router = useNavigate()
  const back = () => {
    router(-1)
  }
  const goHome = () => {
    router('/', { replace: true })
  }
  return (
    <>
      <div className={styles.notFound}>
        <div className={styles.image}></div>
        <div className={styles.desc}>抱歉，您访问的页面不存在……</div>
        <div className={styles.backLable}>
          您可以 返回 <span onClick={back}>前一页</span> 或 返回{' '}
          <span
            onClick={() => {
              goHome()
            }}
          >
            {`${siteName}首页`}
          </span>
        </div>
      </div>
    </>
  )
}

export default NotFound
