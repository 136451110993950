import React from 'react'
import { Spinner } from '@nextui-org/react'
import styles from './index.module.scss'

function LoadingPage() {
  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        <div className={styles.loading_img}>
          <Spinner style={{ width: 30, height: 30 }} className={styles.img} />
        </div>
        <div className={styles.loading_text}>正在加载</div>
      </div>
    </div>
  )
}

export default LoadingPage
