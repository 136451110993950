// import toast, { toastConfig, type ConfigArgs } from 'react-simple-toasts'
// import 'react-simple-toasts/dist/theme/dark.css'
// import 'react-simple-toasts/dist/theme/light.css'

// const initOps: ConfigArgs = {
//   theme: 'dark',
//   position: 'top-center',
//   duration: 1500,
//   className: 'text-[12px] p-[10px]'
// }

// toastConfig(initOps)

// export const setTheme = (theme: any) => toastConfig({ ...initOps, theme })

import styles from './index.module.scss'
import Toast, { ToastShowProps } from 'antd-mobile/es/components/toast'
export default (p: ToastShowProps | string) => {
  Toast.show(
    typeof p === 'string'
      ? {
          position: 'top',
          content: p,
          duration: 2000,
          maskClassName: [styles.container, styles.top].join(' ')
        }
      : { ...p, maskClassName: [styles.container].join(' ') }
  )
  // Toast.show(defaultOptions)
}
