import { BusinessError, DOMEvent } from 'dcjiayun-js'
import { isBrowser } from '@/utils'
import { encrypt, decrypt } from './aes'
import { BaseReq } from '@/interfaces/common'
import { UserProfile } from '@/interfaces/user.store'
import userKey from '@/constants/userKey'
import { isEmpty } from 'lodash'

// Token 加密
export const encryptUid = encodeURIComponent(encrypt('APIUID'))

// 获取UID
export function getUID(): number {
  return Number(decrypt(window.localStorage.getItem(encryptUid) || ''))
}

/**
 * 保存UID到cookie
 * @param uid
 */
export function saveUID(uid: string) {
  window.localStorage.setItem(encryptUid, encrypt(uid))
}

// 获取凭证信息
export function getCredentials(): 'true' | 'false' {
  return (
    (window.localStorage.getItem('credentials') as 'true' | undefined) ||
    'false'
  )
}

/**
 * 保存凭证信息
 * @param uid
 */
export function saveCredentials(credentials: string) {
  window.localStorage.setItem('credentials', credentials)
}

// Token 加密
export const encryptTOKEN = encodeURIComponent(encrypt('APITOKEN'))

/**
 * 支持从服务端客户端读取Token
 */
export function getToken(): string {
  return decrypt(window.localStorage.getItem(encryptTOKEN) || '')
}

/**
 * 保存token到cookie
 * @param token
 */
export function saveToken(token: string) {
  window.localStorage.setItem(encryptTOKEN, encrypt(token))
}

/**
 * 获取Token ，UID
 * @returns
 */
export function getBaseReq(): BaseReq {
  return {
    uid: Number(getUID()),
    token: getToken()
  }
}

/**
 * 保存用户信息
 * @returns
 */
export function saveUserInfo(useInfo: UserProfile) {
  return window.localStorage.setItem(
    userKey.key,
    encrypt(JSON.stringify(useInfo))
  )
}

/**
 * 从token 获取用户信息
 * @returns
 */
export function getUserInfo(): UserProfile | undefined {
  const userinfostr = window.localStorage.getItem(userKey.key)
  const decodeInfo = decodeURIComponent(
    !isEmpty(userinfostr) ? userinfostr || '' : ''
  )
  const info = !isEmpty(decodeInfo) ? decrypt(decodeInfo) : ''
  try {
    return !isEmpty(info) && JSON.parse(info)
  } catch {
    return undefined
  }
}

/**
 * 删除Token从cookie中
 * @param token
 */
export async function removeToken() {
  window.localStorage.removeItem(encryptTOKEN)
  window.localStorage.removeItem(encryptUid)
  window.localStorage.removeItem(userKey.key)
  window.localStorage.removeItem('credentials')
}

/**
 * 监听有异常的错误code
 * '99991' 监听token失效的错误码
 * @param callback
 */
export function onTokenExpired(
  callback: (e: DOMEvent.MyEvent<BusinessError>) => void
) {
  const arr = [
    '99991',
    '99992',
    '99993',
    '99994',
    '99995',
    '99996',
    '99997',
    '99998',
    '99999'
  ]
  arr.forEach((code) => {
    DOMEvent.on(`${BusinessError.name}/code/${code}`, callback)
  })
}

isBrowser() && onTokenExpired(removeToken)
