import { post } from '@/utils/request'
import {
  AdvertiseRes,
  BottomNavigationRes,
  CommonConfigRes,
  CommonGlobalUploadTokenRes,
  Values
} from '@/interfaces/common'
import { ADVERTISE_TYPE } from '../constants/common'
import { getBaseReq } from '@/utils/session'
import { getNativePlatform } from '@/utils/native'
/**
 * 公共-底部导航栏目
 * @returns BottomNavigationRes
 */
export const getBottomNavigation = async (): Promise<
  Array<BottomNavigationRes>
> => {
  return post<Array<BottomNavigationRes>>('/api/v2/video/bottom/navigation')
}

/**
 * 广告配置
 * pos: [1,2,3,4]
 */
export const getAdvertisement = async (
  pos: Array<Values<typeof ADVERTISE_TYPE>>
): Promise<Array<AdvertiseRes>> => {
  return post<Array<AdvertiseRes>>('/api/v2/video/adv/pos', {
    body: JSON.stringify({ pos })
  })
}

/**
 * 统计广告点击
 * @param id 广告ID
 * @returns
 */
export const getAdvPostClick = async (id: number): Promise<any> => {
  const params = {
    id,
    ...getBaseReq()
  }
  return post<any>('/api/v2/video/adv/pos/click', {
    body: JSON.stringify({ ...params })
  })
}

/**
 * 全局文件上传
 * @param file 上传的文件
 * @returns
 */
export const getGlobalUpload = async (
  file?: File
): Promise<{
  file: string
  path: string
}> => {
  const formData = new FormData()
  if (file) {
    formData.append('file', file)
    formData.append('file', file.name)
    formData.append('os', getNativePlatform())
  }
  return post<{
    file: string
    path: string
  }>('/api/v2/global/upload', {
    body: formData,
    method: 'POST'
  })
}

/**
 * 获取启动页，弹出框样式
 */
export const getGlobalConfig = async (): Promise<CommonConfigRes> => {
  return post<CommonConfigRes>('/api/v2/global/config')
}

/**
 * AWS-S3 上传获取Token
 */
export const getGlobalUploadToken = async ({
  uid,
  token
}: {
  uid: number
  token: string
}): Promise<CommonGlobalUploadTokenRes> => {
  return post<CommonGlobalUploadTokenRes>('/api/v2/global/upload/token', {
    body: JSON.stringify({ uid, token })
  })
}
