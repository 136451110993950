import React, { useContext } from 'react'
import { InitRouterDataRes, LayoutDataProps } from './interfaces/common'
import { getShortVideoList } from './api/video'
import { postAppGlobeCenter } from './api/apps'
import {
  getBlogsCircle,
  getBlogsNavigationList,
  getPostList
} from './api/blogs'
import { DispatchWithCallback } from './hooks/useStateCallback'

export interface ContextType {
  appData?: LayoutDataProps
  updateAppData: (newData: LayoutDataProps) => void
}

const AppContext = React.createContext<ContextType>(undefined as any)

export default AppContext

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useAppContext must be used within a MyContextProvider')
  }
  return context
}

// 考虑第一次切换底部导航因为客户端的加载没有默认数据问题 在启动应用层查询一次底部导航路由数据
export const getInitRouterData = async ({
  uid,
  token,
  setInitRouterData
}: {
  uid: number
  token: string
  setInitRouterData: DispatchWithCallback<
    React.SetStateAction<Pick<InitRouterDataRes, keyof InitRouterDataRes>>
  >
}) => {
  // 每个路由的页面初始化加载如果报错不能影响其他页面接口加载
  try {
    getShortVideoList().then((data) => {
      setInitRouterData({ videoListResDefault: data })
    })
  } catch (error) {
    console.info(error)
  }
  try {
    postAppGlobeCenter().then((data) => {
      setInitRouterData({ appGlobalCenter: data })
    })
  } catch (error) {
    console.info(error)
  }

  try {
    // 获取社区默认数据
    getBlogsNavigationList({
      navigation_type: 0,
      token,
      uid
    }).then((data) => {
      setInitRouterData({
        blogsNavigationDefault: data,
        blogsNid:
          data && Array.isArray(data) && data.length > 0 ? data[0].nid : 0
      })

      if (data && Array.isArray(data) && data.length > 0) {
        const selfNid = data[0].nid
        getBlogsCircle({
          nid: selfNid,
          token,
          uid
        }).then((circleData) => {
          setInitRouterData({ blogsCircleDefault: circleData })
        })
        getPostList({
          type: 0,
          category_id: selfNid,
          sort_type: 1,
          token,
          uid,
          page: 1,
          page_size: 8
        }).then((postList) => {
          setInitRouterData({ blogsPostListDefault: postList })
        })
      }
    })
  } catch (error) {
    console.info(error)
  }

  try {
    // 获取圈子默认数据
    getBlogsNavigationList({
      navigation_type: 1,
      token,
      uid
    }).then((data) => {
      setInitRouterData({
        circleNavigationDefault: data,
        circleNid:
          data && Array.isArray(data) && data.length > 0 ? data[0].nid : 0
      })

      if (data && Array.isArray(data) && data.length > 0) {
        const selfNid = data[0].nid
        getBlogsCircle({
          nid: selfNid,
          token,
          uid
        }).then((circleData) => {
          setInitRouterData({ circleCircleDefault: circleData })
        })
        getPostList({
          type: 0,
          category_id: selfNid,
          sort_type: 1,
          token,
          uid,
          page: 1,
          page_size: 8
        }).then((postList) => {
          setInitRouterData({ circlePostListDefault: postList })
        })
      }
    })
  } catch (error) {
    console.info(error)
  }

  try {
    // 获取种子默认数据
    getBlogsNavigationList({
      navigation_type: 2,
      token,
      uid
    }).then((data) => {
      setInitRouterData({
        btsNavigationDefault: data,
        btsNid: data && Array.isArray(data) && data.length > 0 ? data[0].nid : 0
      })
      if (data && Array.isArray(data) && data.length > 0) {
        const selfNid = data[0].nid
        getBlogsCircle({
          nid: selfNid,
          token,
          uid
        }).then((circleData) => {
          setInitRouterData({ btsCircleDefault: circleData })
        })
        getPostList({
          type: 0,
          category_id: selfNid,
          sort_type: 1,
          token,
          uid,
          page: 1,
          page_size: 8
        }).then((postList) => {
          setInitRouterData({ btsPostListDefault: postList })
        })
      }
    })
  } catch (error) {
    console.info(error)
  }
}
