import { PageReq } from '@/interfaces/common'

/**
 * 广告 type
 */
export const ADVERTISE_TYPE = {
  /**
   * 
   1. 启动页广告
    2. 首页弹窗广告
    3. 首页banner
    4. 视频播放详情页广告
    5. 长视频列表广告
    6. 搜索页广告
    7. 圈子页面-banner
   */

  // 1. 启动页广告
  APP_LAUNCH: 1,
  // 2. 首页弹窗广告
  HOME_POPUP: 2,
  // 3. 首页banner
  HOME_BANNER: 3,
  // 4. 视频播放详情页广告
  VIDEO_DETAIL: 4,
  // 5. 搜索广告-上
  SEARCH_TOP: 5,
  // 6. 搜索页广告
  SEARCH: 6,
  //  7. 出品方广告
  SPONSOR: 7,
  //  8. 明星广告
  STARS: 8,
  //  9. 频道更新广告-上
  CHANNEL_TOP: 9,
  //  10. 频道更新广告-下
  CHANNEL_BOTTOM: 10,
  // 11. 视频顶部广告
  VIDEO_TOP: 11,
  // 12. 视频中部广告
  VIDEO_MIDDLE: 12,
  // 13. 长视频广告—右下
  LONG_VIDEO: 13,
  // 14. 游戏顶部
  GAMES_TOP: 14,
  // 15. 用户广告
  USER: 15,
  // 16. 短视频广告
  SHORT_VIDEO: 16,
  // 17. 配置模块广告
  MODULE: 17
} as const

export const INIT_PAGE: PageReq = {
  page: 1,
  page_size: 20
} as const

// 收藏类型
export const COLLECT_TYPE = {
  // 1 会员收藏视频 2 会员点赞视频评论 3. 会员点赞短视频 4. 会员收藏短视频 5. 会员收藏帖子 6. 会员点赞帖子
  //  7. 会员点赞帖子评论 8. UP主关注 9 帖子分类关注 10. 会员收藏种子 11 会员点赞种子 12 会员点赞圈子 13 会员收藏圈子
  COLLECT_VIDEO: 1,
  LIKE_VIDEO_COMMENT: 2,
  LIKE_SHORT_VIDEO: 3,
  COLLECT_SHORT_VIDEO: 4,
  COLLECT_POST: 5,
  LIKE_POST: 6,
  LIKE_POST_COMMENT: 7,
  FOLLOW_UP: 8,
  FOLLOW_POST_CATEGORY: 9,
  FOLLOW_SEED: 10,
  LIKE_SEED: 11,
  FOLLOW_CIRCLE: 12,
  COLLECT_CIRCLE: 13
} as const

export const JUMP_TYPE = {
  // 1内部跳转  2外部跳转
  INTERNAL: 1,
  EXTERNAL: 2
} as const

// 购买-金币类型
export const BUY_TYPE = {
  VIDEO: 1,
  BLOGS: 2
}
