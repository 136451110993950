import React, { useContext } from 'react'
import useStateCallback from '@/hooks/useStateCallback'
import { BottomNavigationRes } from '@/interfaces/common'
import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import toast from '../toast'
import styles from './index.module.scss'
import AppContext, { ContextType } from '@/AppContextProvider'
import { switchSVG } from '@/themes/sm/svg/footer'
const { themes } =
  require(`@/themes/${process.env.NEXT_PUBLIC_PLATFORM}/config`).default

const Footer: React.FC = () => {
  const { appData } = useContext<ContextType>(AppContext)
  const router = useNavigate()
  const location = useLocation()
  const [state, setState] = useStateCallback<{
    active: string
  }>({ active: '/' })

  const elementRef = useRef<HTMLElement>(null)
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    setState({
      active: location.pathname === '/' ? '/home' : location.pathname
    })

    elementRef.current && setHeight(elementRef.current.offsetHeight)
  }, [setState, location.pathname])

  const go = (item: BottomNavigationRes) => () => {
    if (item.status === 3) {
      toast(item.remark || '菜单维护中，请稍后在试')
    } else if (item.jump_type === 2) {
      if (window?.native?.openUrl) {
        window.native.openUrl(item.url, 2)
        return
      } else {
        window.open(item.url)
      }
    } else {
      router(item.alias === 'home' ? '/' : item.alias)
    }
  }
  return (
    <>
      <footer id="footer" className={styles.container} ref={elementRef}>
        <ul>
          {appData?.bottomNavData &&
            Array.isArray(appData.bottomNavData) &&
            appData.bottomNavData.length > 0 &&
            appData.bottomNavData.map((item, index) => {
              if (item.status === 2) {
                return null
              }
              return (
                <li
                  key={index}
                  className={[
                    state.active === `/${item.alias}` ? styles.active : '',
                    item.status === 3 ? styles.disable : ''
                  ].join(' ')}
                  onClick={go(item)}
                >
                  {switchSVG(
                    item,
                    state.active === `/${item.alias}`
                      ? themes?.footerSVG?.activeBgColor
                      : themes?.footerSVG?.defaultBgColor,
                    state.active === `/${item.alias}`
                      ? themes?.footerSVG?.activeIconColor
                      : // 没办法这个 apps 应用中心svg 图标不符合规范，只能代码特殊判断
                      process.env.NEXT_PUBLIC_PLATFORM === 'sm' &&
                        item.alias === 'apps'
                      ? themes?.footerSVG?.defaultBgColor
                      : themes?.footerSVG?.defaultIconColor
                  )}
                  {item.title}
                </li>
              )
            })}
        </ul>
      </footer>

      <div style={{ height: `${height}px` }} />
    </>
  )
}
export default Footer
