import React from 'react'
// import { createRoot } from 'react-dom/client'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import { persistor, store } from './store/reducers/store'
import { PersistGate } from 'redux-persist/integration/react'
import { HashRouter } from 'react-router-dom'
import { AliveScope } from 'react-activation'
// import VConsole from 'vconsole'
// const vConsole = new VConsole()
// console.info(vConsole)
// 如果用createRoot文件可以是tsx
// const root = createRoot(document.getElementById('root')!) // createRoot(container!) if you use TypeScript
// root.render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <BrowserRouter>
//         <AliveScope>
//           <App />
//         </AliveScope>
//       </BrowserRouter>
//     </PersistGate>
//   </Provider>
// )
// ReactDOM.render babel-loader find index.js
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HashRouter>
        <AliveScope>
          <App />
        </AliveScope>
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

serviceWorker.register()
