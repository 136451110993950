import { BottomNavigationRes } from '@/interfaces/common'
import React from 'react'

export const switchSVG = (
  item: BottomNavigationRes,
  bgColor: string,
  activeColor: string
) => {
  switch (item.alias) {
    case 'home':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_12582)">
            <g filter="url(#filter0_ii_1_12582)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.83481 6.875C1.29483 8.002 1.48482 9.321 1.86481 11.958L2.14281 13.895C2.6298 17.283 2.87379 18.976 4.04876 19.988C5.22373 21 6.94669 21 10.3936 21H12.6056C16.0525 21 17.7754 21 18.9504 19.988C20.1254 18.976 20.3694 17.283 20.8564 13.895L21.1354 11.958C21.5154 9.321 21.7053 8.002 21.1644 6.875C20.6244 5.748 19.4734 5.062 17.1725 3.692L15.7875 2.867C13.6995 1.622 12.6536 1 11.4996 1C10.3456 1 9.30064 1.622 7.21169 2.867L5.82672 3.692C3.52677 5.062 2.3758 5.748 1.83481 6.875Z"
                fill={bgColor}
              />
            </g>
            <g filter="url(#filter1_d_1_12582)">
              <rect
                x="7.5"
                y="15"
                width="8"
                height="2"
                rx="1"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_ii_1_12582"
              x="-0.5"
              y="-1"
              width="23"
              height="24"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="-2" dy="-2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_1_12582"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="2" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
              />
              <feBlend
                mode="normal"
                in2="effect1_innerShadow_1_12582"
                result="effect2_innerShadow_1_12582"
              />
            </filter>
            <filter
              id="filter1_d_1_12582"
              x="6.5"
              y="14"
              width="12"
              height="6"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_12582"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_12582"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_1_12582">
              <rect
                width="21"
                height="21"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      )
    case 'videos':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_1_12590)">
            <path
              d="M10.5931 20C8.59661 20 6.57242 19.8359 4.57681 19.5123L4.56463 19.5103C2.92963 19.2255 1.33251 17.7967 1.00446 16.3253L1.00108 16.3098C0.270474 12.7865 0.270474 9.21345 1.00108 5.69015L1.00438 5.67493C1.33217 4.20323 2.92937 2.77433 4.5648 2.48966L4.57689 2.48763C6.57242 2.16398 8.59653 2 10.5931 2C12.5896 2 14.6137 2.16407 16.6095 2.48772L16.6217 2.48975C18.2568 2.7745 19.8538 4.20332 20.1818 5.67493L20.185 5.69015C20.9157 9.21353 20.9157 12.7866 20.185 16.3099C20.184 16.315 20.1829 16.32 20.1818 16.3251C19.8541 17.7965 18.2571 19.2255 16.6218 19.5103C16.6177 19.5111 16.6136 19.5118 16.6095 19.5124C14.6137 19.8358 12.5895 20 10.5931 20Z"
              fill={bgColor}
            />
            <g filter="url(#filter0_d_1_12590)">
              <path
                d="M9.24336 15C8.99856 14.9999 8.76382 14.902 8.59073 14.7279C8.41764 14.5537 8.32037 14.3175 8.32031 14.0712V7.92885C8.32031 7.75674 8.36783 7.58802 8.45757 7.4415C8.54732 7.29498 8.67576 7.17643 8.82857 7.09908C8.98137 7.02174 9.15253 6.98863 9.32295 7.00345C9.49337 7.01828 9.65634 7.08046 9.79368 7.18305L13.9475 10.286C14.0638 10.3729 14.1581 10.486 14.2229 10.6164C14.2876 10.7467 14.321 10.8906 14.3203 11.0363C14.3196 11.182 14.2848 11.3256 14.2188 11.4553C14.1527 11.585 14.0573 11.6972 13.9402 11.7829L9.78637 14.8223C9.62859 14.9378 9.43848 15 9.24336 15Z"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_1_12590"
              x="7.32031"
              y="6"
              width="10"
              height="12"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_12590"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_12590"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_1_12590">
              <rect
                width="21"
                height="21"
                fill="white"
                transform="translate(0.226562)"
              />
            </clipPath>
          </defs>
        </svg>
      )
    case 'apps':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133879">
            <g id="Group 2048133864">
              <path
                id="Subtract"
                d="M19.5531 6.05C19.5531 8.28675 17.7399 10.1 15.5031 10.1C13.2664 10.1 11.4531 8.28675 11.4531 6.05C11.4531 3.81325 13.2664 2 15.5031 2C17.7399 2 19.5531 3.81325 19.5531 6.05Z"
                fill={activeColor}
              />
              <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.62742 2H7.12815C8.61907 2 9.82894 3.20988 9.82656 4.70079V7.40159C9.82656 8.8925 8.61907 10.1 7.12815 10.1H4.42498C2.93406 10.1 1.72656 8.8925 1.72656 7.40159V4.90085C1.72656 3.298 3.02456 2 4.62742 2ZM4.62742 11.9H7.12815C8.61907 11.9 9.82894 13.1099 9.82656 14.6008V17.3016C9.82656 18.7925 8.61907 20 7.12815 20H4.42498C2.93406 20 1.72656 18.7925 1.72656 17.3016V14.8009C1.72656 13.198 3.02456 11.9 4.62742 11.9ZM17.0276 11.8999H14.5268C12.924 11.8999 11.626 13.1979 11.626 14.8008V17.3015C11.626 18.7924 12.8335 19.9999 14.3244 19.9999H17.0276C18.5185 19.9999 19.726 18.7924 19.726 17.3015V14.6007C19.7284 13.1098 18.5185 11.8999 17.0276 11.8999Z"
                fill={bgColor}
              />
            </g>
          </g>
        </svg>
      )

    case 'games':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="game_on" clipPath="url(#clip0_4275_13658)">
            <path
              id="Union"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.3843 2C10.8959 2 10.5 2.3959 10.5 2.88427V4.30594H7.37387C5.54403 4.30594 3.8199 5.07697 2.51862 6.47565C1.21734 7.87433 0.5 9.72751 0.5 11.6943V11.7051C0.5 13.6692 1.21734 15.5224 2.51862 16.9211C3.8199 18.3198 5.54403 19.0908 7.37387 19.0908H14.6236C16.4535 19.0908 18.1801 18.3225 19.4814 16.9238C20.7827 15.5251 21.5 13.6719 21.5 11.7051V11.6943C21.5 9.72751 20.7827 7.87433 19.4814 6.47565C18.1801 5.07697 16.456 4.30594 14.6261 4.30594H12.2685V2.88427C12.2685 2.3959 11.8726 2 11.3843 2Z"
              fill={bgColor}
            />
            <g id="Union_2" filter="url(#filter0_d_4275_13658)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.97104 12.4629C5.27356 12.4629 5.5186 12.7085 5.51789 13.011C5.51718 13.313 5.76144 13.5585 6.06347 13.5592L6.42221 13.56C6.72589 13.5607 6.97265 13.3151 6.97337 13.0114C6.97408 12.7083 7.22548 12.4629 7.52865 12.4629C7.83841 12.4629 8.09506 12.2118 8.09506 11.902V11.5652C8.09506 11.2571 7.84531 11.0074 7.53724 11.0074C7.22865 11.0074 6.97869 10.7568 6.97942 10.4483L6.97955 10.3953C6.98035 10.058 6.70758 9.78396 6.37031 9.78317L6.13932 9.78263C5.80032 9.78183 5.52484 10.0615 5.52404 10.4005C5.52326 10.7329 5.25358 11.0074 4.9212 11.0074C4.58826 11.0074 4.31836 11.2773 4.31836 11.6102V11.8632C4.31836 12.1944 4.58686 12.4629 4.91806 12.4629H4.97104ZM12.8409 11.6702C12.8409 12.8308 13.785 13.7723 14.9429 13.7723C16.1035 13.7723 17.045 12.8308 17.045 11.6702C17.045 10.5096 16.1035 9.56812 14.9429 9.56812C13.7823 9.56812 12.8409 10.5096 12.8409 11.6702ZM14.0583 11.6729C14.0583 11.1859 14.456 10.7882 14.9429 10.7882C15.4326 10.7882 15.8276 11.1859 15.8276 11.6729C15.8276 12.1599 15.4299 12.5576 14.9429 12.5576C14.456 12.5576 14.0583 12.1599 14.0583 11.6729Z"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13658"
              x="3.31836"
              y="8.56812"
              width="16.7266"
              height="8.2041"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13658"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13658"
                result="shape"
              />
            </filter>
            <clipPath id="clip0_4275_13658">
              <rect
                width="21"
                height="21"
                fill="white"
                transform="translate(0.5)"
              />
            </clipPath>
          </defs>
        </svg>
      )

    case 'blogs':
      return (
        <svg
          width="24"
          height="21"
          viewBox="0 0 24 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133881">
            <g id="Group 2048133796">
              <path
                id="Vector"
                d="M15.3592 20C15.0619 20 14.7646 19.9147 14.5064 19.7453L11.4195 17.7198H5.99203C3.64075 17.7198 1.72656 15.8068 1.72656 13.4543V6.26428C1.72656 3.913 3.63957 2 5.99203 2H17.2035C19.5548 2 21.469 3.913 21.469 6.26546V13.4543C21.469 15.8056 19.556 17.7198 17.2035 17.7198H16.9145V18.4447C16.9145 19.0168 16.6018 19.5404 16.0984 19.8128C15.8662 19.9384 15.6127 20 15.3592 20Z"
                fill={bgColor}
              />
            </g>
            <g id="Union" filter="url(#filter0_d_4275_13696)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.1527 9.76804H15.0194C15.5429 9.76804 15.9658 9.34398 15.9658 8.82042C15.9658 8.29686 15.5417 7.8728 15.0182 7.8728H8.1527C7.62914 7.8728 7.20508 8.29686 7.20508 8.82042C7.20508 9.34398 7.62914 9.76804 8.1527 9.76804ZM8.1527 12.7613H11.0429C11.5665 12.7613 11.9905 12.3373 11.9905 11.8137C11.9905 11.2901 11.5665 10.8661 11.0429 10.8661H8.1527C7.62914 10.8661 7.20508 11.2901 7.20508 11.8137C7.20508 12.3373 7.62914 12.7613 8.1527 12.7613Z"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13696"
              x="6.20508"
              y="6.8728"
              width="12.7607"
              height="8.88843"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13696"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13696"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )

    case 'my':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133882">
            <g id="my_svg">
              <path
                id="Union"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9399 7.38207C15.9399 10.3498 13.5256 12.7641 10.5579 12.7641C7.58994 12.7641 5.17558 10.3498 5.17578 7.38207C5.17578 4.41436 7.59015 2 10.5579 2H15.9399V7.38207ZM5.00199 13.4492H16.0664C17.8726 13.4492 19.3419 14.9185 19.3419 16.7247C19.3419 18.5308 17.8726 20.0001 16.0664 20.0001H5.00199C3.19584 20.0001 1.72656 18.5308 1.72656 16.7247C1.72656 14.9185 3.19584 13.4492 5.00199 13.4492Z"
                fill={bgColor}
              />
              <g id="Vector" filter="url(#filter0_d_4275_13704)">
                <path
                  d="M10.2607 16.9999C10.2607 17.5513 10.7186 18 11.2817 18H15.2398C15.8028 18 16.2607 17.5513 16.2607 16.9999V16H11.2817C10.7186 16.0003 10.2607 16.4484 10.2607 16.9999Z"
                  fill={activeColor}
                />
              </g>
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13704"
              x="9.26074"
              y="15"
              width="10"
              height="6"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13704"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13704"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )
    case 'circle':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.84 8.62608C18.84 13.3901 14.978 17.2522 10.214 17.2522C5.44992 17.2522 1.58789 13.3901 1.58789 8.62608C1.58789 3.86203 5.44992 0 10.214 0C14.978 0 18.84 3.86203 18.84 8.62608Z"
            fill={bgColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5344 6.34365C19.6082 4.85727 20.0138 3.60217 19.515 2.87864C19.0483 2.20167 17.865 2.09924 16.2639 2.47792C16.6353 2.8434 16.9737 3.24228 17.2743 3.66967C17.5434 3.63177 17.7769 3.6173 17.9735 3.62295C18.1724 3.62866 18.2938 3.65224 18.3632 3.67276C18.3577 3.7449 18.3365 3.86673 18.2711 4.05462C18.1992 4.26134 18.0878 4.50184 17.9332 4.77284C18.1823 5.2709 18.3846 5.79642 18.5344 6.34365ZM2.2056 11.839C0.920094 13.4913 0.397093 14.9014 0.938141 15.6862C1.51173 16.5182 3.16754 16.4824 5.35105 15.7523C4.89974 15.4437 4.47925 15.0934 4.09512 14.7069C3.41557 14.8838 2.87361 14.9532 2.4796 14.9419C2.28073 14.9362 2.15935 14.9126 2.08997 14.8921C2.09547 14.8199 2.1166 14.6981 2.18199 14.5102C2.3029 14.1628 2.53538 13.7199 2.8967 13.1971C2.62821 12.7683 2.39638 12.3141 2.2056 11.839ZM18.3608 3.58648C18.361 3.58642 18.3615 3.58816 18.3621 3.59187C18.3611 3.5884 18.3607 3.58654 18.3608 3.58648ZM2.01016 14.8592C2.01026 14.8592 2.01187 14.8601 2.01473 14.8624C2.01149 14.8604 2.01007 14.8593 2.01016 14.8592ZM2.09228 14.9783C2.09217 14.9784 2.09164 14.9767 2.09099 14.973C2.09206 14.9764 2.09239 14.9783 2.09228 14.9783ZM18.4384 3.70247C18.4416 3.7044 18.4431 3.70551 18.443 3.70559C18.4429 3.70567 18.4412 3.70471 18.4384 3.70247Z"
            fill={bgColor}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.2054 12.1649C9.76296 13.8488 7.33928 15.0888 5.35191 15.7542C4.90052 15.4459 4.47994 15.0957 4.0957 14.7094C4.27128 14.6636 4.45601 14.6106 4.64973 14.5501C6.52664 13.9631 8.9478 12.7564 11.4323 11.0435C13.9168 9.33063 15.9056 7.49687 17.1217 5.95142C17.4668 5.51284 17.734 5.11724 17.9307 4.77148C18.18 5.26967 18.3825 5.79535 18.5324 6.34276C17.2476 8.12316 15.003 10.2361 12.2054 12.1649Z"
            fill={activeColor}
          />
          <circle cx="11.1218" cy="4.08564" r="1.36201" fill={activeColor} />
        </svg>
      )

    case 'bts':
      return (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="Frame 2048133883">
            <path
              id="Union"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5 2C3.89543 2 3 2.89543 3 4V18C3 19.1046 3.89543 20 5 20H17C18.1046 20 19 19.1046 19 18V4C19 2.89543 18.1046 2 17 2H5ZM16 15.5H6C5.17157 15.5 4.5 16.1716 4.5 17C4.5 17.8284 5.17157 18.5 6 18.5H16C16.8284 18.5 17.5 17.8284 17.5 17C17.5 16.1716 16.8284 15.5 16 15.5ZM16 18C16.5523 18 17 17.5523 17 17C17 16.4477 16.5523 16 16 16C15.4477 16 15 16.4477 15 17C15 17.5523 15.4477 18 16 18Z"
              fill={bgColor}
            />
            <g id="BT" filter="url(#filter0_d_4275_13647)">
              <path
                id="Vector"
                d="M11.1973 6H16.0002V7.23465H14.3888V11H12.8087V7.23465H11.1973V6Z"
                fill={activeColor}
              />
              <path
                id="Vector_2"
                d="M6 6H8.9578C9.45076 6 9.82862 6.11937 10.0914 6.35812C10.3565 6.59686 10.489 6.89245 10.489 7.24488C10.489 7.54047 10.3948 7.794 10.2065 8.00546C10.0809 8.14643 9.89722 8.25784 9.65539 8.3397C10.0228 8.4261 10.2925 8.57503 10.4646 8.78649C10.639 8.99568 10.7262 9.25944 10.7262 9.57776C10.7262 9.83697 10.6646 10.07 10.5413 10.2769C10.4181 10.4839 10.2495 10.6476 10.0356 10.7681C9.90303 10.8431 9.70306 10.8977 9.43565 10.9318C9.07987 10.9773 8.84386 11 8.72759 11H6V6ZM7.594 7.96112H8.28113C8.52761 7.96112 8.69852 7.92019 8.79386 7.83834C8.89152 7.75421 8.94036 7.6337 8.94036 7.47681C8.94036 7.33129 8.89152 7.2176 8.79386 7.13574C8.69852 7.05389 8.5311 7.01296 8.29159 7.01296H7.594V7.96112ZM7.594 9.92565H8.39972C8.67178 9.92565 8.86362 9.87904 8.97524 9.78581C9.08685 9.69031 9.14266 9.56298 9.14266 9.40382C9.14266 9.25603 9.08685 9.13779 8.97524 9.04911C8.86595 8.95816 8.67294 8.91269 8.39623 8.91269H7.594V9.92565Z"
                fill={activeColor}
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_d_4275_13647"
              x="5"
              y="5"
              width="14"
              height="9"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_4275_13647"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_4275_13647"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )

    case 'anwang':
      return (
        <svg
          width="27"
          height="21"
          viewBox="0 0 27 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.4874 3.6204L21.543 9.0111L24.108 10.0908C26.0697 10.821 26.6041 13.2771 24.3507 13.7815C23.0173 14.0802 21.7178 14.3209 20.4353 14.5067C20.1127 18.1784 19.0769 21.3387 16.2961 19.4209C15.2074 18.7487 14.4043 17.7958 13.4465 17.7958C12.4886 17.7958 9.97151 20.5956 8.45828 19.8834C7.20974 19.326 6.7283 16.6192 6.56549 14.4218C5.34725 14.2417 4.13392 14.0299 2.92674 13.7865C1.87497 13.5798 1.27667 12.4741 1.49941 11.689C1.72315 10.9019 2.25353 10.3625 2.93273 10.1068L5.70449 9.00011C6.52354 6.82765 7.19276 5.06171 7.71215 3.7013C8.49124 1.66069 10.5398 1.66069 11.8004 2.66052C12.4506 3.17692 12.8801 3.61441 13.4305 3.62539C13.9798 3.63538 14.5092 3.17592 15.1884 2.66052C16.5019 1.66069 18.4087 1.65769 19.4874 3.6204Z"
            fill={bgColor}
          />
          <g filter="url(#filter0_d_1_12606)">
            <path
              d="M15.3015 15.8344C14.9619 16.1211 14.7941 16.4098 15.759 16.9911C16.9445 17.4993 17.5932 17.0532 17.936 16.4669C18.4058 15.6632 17.8096 15.0835 16.9038 15.2982C16.2071 15.4634 15.4737 15.6895 15.3015 15.8344ZM10.3811 15.4282C9.06627 15.0454 8.16371 16.1823 9.2804 16.9749C9.6622 17.2459 10.2177 17.328 11.0176 16.9851C11.2496 16.8452 11.4137 16.7204 11.5262 16.609C11.9895 16.1506 11.0068 15.6103 10.3811 15.4282Z"
              fill={activeColor}
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1_12606"
              x="7.77637"
              y="14.2529"
              width="13.3242"
              height="5.95996"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="1" dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.00640384 0 0 0 0 0.279639 0 0 0 0 0.222189 0 0 0 0.5 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_12606"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_12606"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      )

    default:
      return (
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10.5" cy="10.5" r="10.5" fill="#AAAAAA" />
        </svg>
      )
  }
}
