import { SyntheticEvent } from 'react'

/**
 * 是否是浏览器
 * @returns Boolean true/false
 */
export function isBrowser() {
  try {
    return 'location' in window
  } catch (e) {
    return false
  }
}
/**
 * 是否是安卓或者IOS
 * @returns Android iOS ｜ Unknown
 */
export function detectDeviceType() {
  const userAgent = navigator.userAgent || navigator.vendor
  // opera 检测被废弃
  // Android 检测
  if (/android/i.test(userAgent)) {
    return 'Android'
  }
  // iOS 检测
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    //window.MSStream 已废弃
    return 'iOS'
  }

  return 'Unknown'
}

export function sleep(ms = 0) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function cancelBubble(e: SyntheticEvent) {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
}

export const copyToClipboard = async (text: string) => {
  try {
    if (navigator?.clipboard?.writeText) {
      await navigator.clipboard.writeText(text)
    } else {
      throw new Error('writeText not supported')
    }
  } catch {
    const tempTextArea = document.createElement('textarea')
    tempTextArea.value = text
    document.body.appendChild(tempTextArea)
    tempTextArea.select()
    document.execCommand('copy')
    document.body.removeChild(tempTextArea)
  }
}

declare global {
  interface Navigator {
    standalone?: boolean;
  }
}
