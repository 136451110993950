// 可以把常用需要跳转的路由配置在这，需要跳转某个路由，引入这个文件
export const ROUTES = {
  HOMEPAGE_ROUTE: '/',
  HOMEPAGE_VIDEO: '/home/video/:id',
  HOMEPAGE_SEARCH: '/home/search',
  HOMEPAGE_SEARCH_RES: '/home/search/:value',
  HOMEPAGE_SEARCH_MODULE: '/home/module/:id',
  VIDEOS_ROUTE: '/videos',

  MY: '/my',
  MY_BUY: '/my/buy',
  MY_COLLECT: '/my/collect',
  MY_COLLECT_SHORT_VIDEO: '/my/collect/:id/:index',
  MY_EXCHANGE: '/my/exchange',
  MY_FEEDBACK: '/my/feedback',
  MY_INVITE: '/my/invite',
  MY_JOIN_GROUP: '/my/joinGroup',
  MY_LOGIN: '/my/login',
  MY_MEMBER: '/my/member',
  MY_FOCUS: '/my/focus',
  MY_ORDER: '/my/order',
  MY_POST: '/my/post',
  MY_PROMOTION: '/my/promotion',
  MY_PROMOTION_RECORD: '/my/promotion/record',
  MY_PROMOTION_RECORD_USELIST: '/my/promotion/record/useList',
  MY_PROXY: '/my/proxy',
  MY_SETTING: '/my/setting',
  MY_WALLET: '/my/wallet',

  BLOGS_DETAIL_ROUTE: '/blogs/article/:id',
  BLOGS_CIRCLE_ROUTE: '/blogs/circle/:id',
  BLOGS_PUBLISH_ROUTE: '/blogs/publish/:type',
  BLOGS_PUBLISH_SECTION_ROUTE: '/blogs/publish/section',
  BTS_DETAIL_ROUTE: '/bts/article/:id',
  BTS_CIRCLE_ROUTE: '/bts/circle/:id',
  BTS_PUBLISH_ROUTE: '/bts/publish/:type',
  BTS_PUBLISH_SECTION_ROUTE: '/bts/publish/section',
  VIDEOS_UP_ROUTE: '/videos/up/:id',
  VIDEOS_UPVIDEO_ROUTE: '/videos/upVideo/:id/:index',

  CIRCLE_DETAIL_ROUTE: '/circle/article/:id',
  CIRCLE_PUBLISH_ROUTE: '/circle/publish/:type',
  CIRCLE_PUBLISH_SECTION_ROUTE: '/circle/publish/section',

  // Others
  SERVICE_ROUTE: '/service',
  RECOMMON_HOT: '/recommendations/:id'
}
