import { BusinessError, ConnectionError, isEmpty } from 'dcjiayun-js'
import { useEffect, useState } from 'react'
import { getMemberInfo } from '@/api/user'
import { getBaseReq, getUserInfo, saveUserInfo } from '@/utils/session'
import { decrypt } from '@/utils/aes'
import { UserInfoBaseOptions, UserProfile } from '@/interfaces/user.store'
let _useUserInfoRequest: Promise<UserProfile> | void
/**
 */
export function useUserInfo({
  initialData,
  manual = false,
  ready = true
}: UserInfoBaseOptions<UserProfile> = {}) {
  initialData = isEmpty(initialData) ? getUserInfo() : initialData;
  if (initialData && typeof initialData === 'string') {
    initialData = JSON.parse(decrypt(initialData))
  }
  const [data = initialData, setData] = useState<
    Readonly<UserProfile> | undefined
  >(initialData as UserProfile)
  const [error, setError] = useState<BusinessError | ConnectionError | void>()
  const [loading, setLoading] = useState<boolean>(false)
  const run = () => {
    // 阻止重复请求
    if (!_useUserInfoRequest) {
      setLoading(true)
      _useUserInfoRequest = getMemberUserInfo().finally(() => {
        setLoading(false)
        _useUserInfoRequest = undefined
      })
    }

    return _useUserInfoRequest
      .then((data) => {
        setData(data)
        saveUserInfo(data)
        return data
      })
      .catch((err) => {
        setError(err)
        return Promise.reject(err)
      })
  }
  const refresh = run
  const mutate = setData
  const result = {
    data,
    loading,
    error,
    refresh,
    mutate
  }

  // 手动触发请求使用场景
  useEffect(() => {
    ready = isEmpty(data) || manual ? false : ready // 如果有缓存数据，且则不请求
    ready && run()
  }, [])

  return result
}

/**
 * 获取用户信息
 */
export function getMemberUserInfo() {
  // const params = { token: getToken(), uid: Number(getUID()) }
  const params = { ...getBaseReq() } as any
  if (!params.token || !params.uid) {
    return Promise.resolve(undefined as any)
  }
  return getMemberInfo(params)
}
