import { post } from '@/utils/request'
import { getBaseReq } from '@/utils/session'
import { AppGlobalCenterRequest } from '@/interfaces/apps'
/**
 * 应用-应用页面nav
 * @returns AppNavRequest
 */
export const postAppGlobeCenter = async (): Promise<AppGlobalCenterRequest> => {
  const params = {
    ...getBaseReq()
  }

  return post<AppGlobalCenterRequest>('/api/v2/global/app/center', {
    body: JSON.stringify(params)
  })
}
