import { post } from '@/utils/request'
import {
  BlogsNavigationListRes,
  BlogsCircleListRes,
  BlogsCircleReq,
  BlogsPostListReq,
  BlogsPostListRes,
  PostPublishReq,
  BlogsNavigationListReq,
  BlogsPostDetailtRes,
  BlogsPostDetailReq,
  BlogsPostCollectReq,
  BlogsPostCollecttRes,
  BlogsPostCommentListReq,
  BlogsPostCommentListRes,
  BlogsPostCommentReq,
  BlogsCircleDetailReq,
  BlogsCircleDetailRes,
  PostCircleListReq,
  PostCircleListRes
} from '@/interfaces/blogs'
/**
 * 社区-社区导航栏目 1 圈子 2社区 3种子
 * @returns BlogsNavigationListRes
 */
export const getBlogsNavigationList = async (
  params: BlogsNavigationListReq
): Promise<BlogsNavigationListRes> => {
  return post<BlogsNavigationListRes>(
    '/api/v2/community/navigation/list/public',
    { body: JSON.stringify(params) }
  )
}

/**
 * 社区-圈子列表
 * @returns BlogsCircleListRes
 */
export const getBlogsCircle = async (
  params: BlogsCircleReq
): Promise<BlogsCircleListRes> => {
  return post<BlogsCircleListRes>('/api/v2/community/circle/list/public', {
    body: JSON.stringify(params)
  })
}

/**
 * 社区-圈子详细
 * @returns BlogsCircleDetailRes
 */
export const getBlogsCircleDetail = async (
  params: BlogsCircleDetailReq
): Promise<BlogsCircleDetailRes> => {
  return post<BlogsCircleDetailRes>('/api/v2/community/circle/detail/public', {
    body: JSON.stringify(params)
  })
}

/**
 * 社区-帖子列表 ---------------- 帖子列表
 * @returns BlogsPostListRes
 */
export const getPostList = async (
  params?: BlogsPostListReq
): Promise<BlogsPostListRes> => {
  return post<BlogsPostListRes>('/api/v2/community/post/detail/list/public', {
    body: JSON.stringify(params)
  })
}

// 热贴banner
export const getPostBanner = async (params?: any): Promise<any> => {
  return post<any>('api/v3/community/circle/list/public', {
    body: JSON.stringify(params)
  })
}
// 全部热帖
export const getHotRecommonList = async (params?: any): Promise<any> => {
  return post<any>('api/v3/community/hot/post/list', {
    body: JSON.stringify(params)
  })
}

/**
 * 社区-帖子详细 ---------------- 帖子详细
 * @returns BlogsCircleListRes
 */
export const getPostDetail = async (
  params?: BlogsPostDetailReq
): Promise<BlogsPostDetailtRes> => {
  return post<BlogsPostDetailtRes>('/api/v2/community/post/detail', {
    body: JSON.stringify(params)
  })
}

/**
 * 社区-帖子详细 ---------------- 帖子收藏，点赞帖子，点赞帖子评论，关注博主，关注帖子分类
 * @returns BlogsCircleListRes
 */
export const getPostCollect = async (
  params: BlogsPostCollectReq
): Promise<BlogsPostCollecttRes> => {
  return post<BlogsPostCollecttRes>('/api/v2/video/collect', {
    body: JSON.stringify(params)
  })
}

/**
 * 社区-帖子 获取评论列表
 * @returns BlogsCircleListRes
 */
export const getPostCommentList = async (
  params: BlogsPostCommentListReq
): Promise<BlogsPostCommentListRes> => {
  return post<BlogsPostCommentListRes>('/api/v2/community/post/comment/list', {
    body: JSON.stringify(params)
  })
}

/**
 * 社区-帖子 评论帖子
 * @returns BlogsCircleListRes
 */
export const getPostComment = async (
  params: BlogsPostCommentReq
): Promise<BlogsPostCommentListRes> => {
  return post<BlogsPostCommentListRes>('/api/v2/community/post/comment', {
    body: JSON.stringify(params)
  })
}

/**
 * 社区-帖子详细 ---------------- 帖子收藏，点赞帖子，点赞帖子评论，关注博主，关注帖子分类
 * @returns BlogsCircleListRes
 */
export const getPostBuy = async (params: {
  id: number
  token: string
  uid: number
}): Promise<{
  created_at: number
  id: number
  money: number
  uid: number
  updated_at: number
}> => {
  return post<{
    created_at: number
    id: number
    money: number
    uid: number
    updated_at: number
  }>('/api/v2/community/buy', { body: JSON.stringify(params) })
}

/**
 * 社区-所有圈子列表
 * @returns any
 */
export const getcircleList = async (
  params: PostCircleListReq
): Promise<PostCircleListRes> => {
  return post<PostCircleListRes>('api/v2/community/circle/list', {
    body: JSON.stringify(params)
  })
}

/**
 * 社区-帖子发布
 * @returns any
 */
export const getPostPublish = async (params: PostPublishReq): Promise<any> => {
  return post('/api/v2/community/up/post/publish', {
    body: JSON.stringify(params)
  })
}
