import {
  ReqGuestLogin,
  ReqLoginRegister,
  ReqMemberInfo,
  UserProfile,
  UserPayForm,
  UserPayOrderType,
  UserFollow,
  UserPostParams,
  UserCollectParams,
  MyCollectListRes,
  AccountCredentialsRes,
  UserBuyParams,
  MyBuyListRes
} from '@/interfaces/user.store'
import { BlogsPostListRes } from '@/interfaces/blogs'
import { post } from '@/utils/request'
import { getBaseReq } from '@/utils/session'
import { PageProps, PageReq } from '@/interfaces/common'

/**
 * 用户登录注册接口
 * @returns UserProfile
 */
export const getUserLogin = async (
  params: ReqLoginRegister | ReqGuestLogin
): Promise<UserProfile> => {
  if ('username' in params) {
    return post<UserProfile>('/api/v1/member/login/new', {
      body: JSON.stringify(params),
      autoToast: false
    })
  } else {
    return post<UserProfile>('/api/v1/member/guest/login', {
      body: JSON.stringify(params)
    })
  }
}

/**
 * 用户登录-获取用户信息
 * @returns UserProfile
 */
export const getMemberInfo = async (
  params: ReqMemberInfo
): Promise<UserProfile> => {
  return post<UserProfile>('/api/v1/member/info', {
    body: JSON.stringify(params)
  })
}

/**
 * 我的关注
 * @returns UserProfile
 */

export const getMyFollow = async (
  payLoad: UserFollow
): Promise<UserProfile> => {
  const params = {
    ...getBaseReq(),
    payLoad
  }
  return post<UserProfile>('/api/v2/community/follow', {
    body: JSON.stringify(params)
  })
}

/**
 * 支付类型 充值通道
 * @returns UserProfile
 */

export const getMyPaymentPlatform = async (): Promise<any> => {
  const params = {
    ...getBaseReq()
  }
  return post<any>('/api/v1/payment/platform', {
    body: JSON.stringify(params)
  })
}

/**
 * 发起充值
 * @returns UserProfile
 */

export const getMyPaymentPay = async (
  details: UserPayForm
): Promise<string> => {
  const params = {
    ...getBaseReq(),
    ...details
  }
  return post<string>('/api/v1/payment/pay', {
    body: JSON.stringify(params)
  })
}

/**
 * 获取我的订单
 * @returns UserProfile
 */

export const getMyPaymentOrder = async (
  details: UserPayOrderType
): Promise<any> => {
  const params = {
    ...getBaseReq(),
    ...details
  }
  return post<any>('/api/v1/payment/order', {
    body: JSON.stringify(params)
  })
}

/**
 * 获取金币消费
 * @returns UserProfile
 */

export const getMyFundDetail = async (details: PageProps): Promise<any> => {
  const params = {
    ...getBaseReq(),
    ...details
  }
  return post<any>('/api/v1/member/fund/detail', {
    body: JSON.stringify(params)
  })
}

/**
 *  邀请记录
 * @returns UserProfile
 */

export const getMyInviteRecord = async (): Promise<any> => {
  const params = {
    ...getBaseReq()
  }
  return post<any>('/api/v1/member/invite/record', {
    body: JSON.stringify(params)
  })
}

/**
 *  获取VIp列表和权益信息
 * @returns UserProfile
 */

export const getMyVipGet = async (): Promise<any> => {
  const params = {
    ...getBaseReq()
  }
  return post<any>('/api/v1/member/vip/get', {
    body: JSON.stringify(params)
  })
}

/**
 * 积分列表配置
 * @returns UserProfile
 */

export const getMyExchangeCfg = async (): Promise<any> => {
  const params = {
    ...getBaseReq()
  }
  return post<any>('/api/v1/member/integral/exchange/cfg', {
    body: JSON.stringify(params)
  })
}

/**
 * 积分兑换
 * @returns UserProfile
 */

export const getMyExchange = async (payLoad: number): Promise<string> => {
  const params = {
    ...getBaseReq(),
    id: payLoad
  }
  return post<string>('/api/v1/member/integral/exchange', {
    body: JSON.stringify(params)
  })
}

/**
 * VIP支付列表
 * @returns UserProfile
 */

export const getMyVipPLatform = async (payLoad: number): Promise<string> => {
  const params = {
    ...getBaseReq(),
    amount: payLoad
  }
  return post<string>('/api/v1/payment/vip/platform', {
    body: JSON.stringify(params)
  })
}

/**
 * 发起VIP充值
 * @returns UserProfile
 */

export const getMyVipPay = async (details: UserPayForm): Promise<string> => {
  const params = {
    ...getBaseReq(),
    ...details
  }
  return post<string>('/api/v1/payment/vip/pay', {
    body: JSON.stringify(params)
  })
}

/**
 * 获取我的帖子
 * @returns UserProfile
 */

export const getMyPostList = async (
  details: UserPostParams
): Promise<BlogsPostListRes> => {
  const params = {
    ...details,
    ...getBaseReq()
  }
  return post<BlogsPostListRes>('/api/v1/member/post/record', {
    body: JSON.stringify(params)
  })
}

/**
 * 获取我的收藏
 * @returns UserProfile
 */

export const getMyCollectList = async (
  details: UserCollectParams
): Promise<MyCollectListRes> => {
  const params = {
    ...details,
    ...getBaseReq()
  }
  return post<MyCollectListRes>('/api/v1/member/collect', {
    body: JSON.stringify(params)
  })
}

/**
 * 获取我的购买
 * @returns UserProfile
 */
export const getMyBuy = async (
  details: UserBuyParams
): Promise<MyBuyListRes> => {
  const params = {
    ...details,
    ...getBaseReq()
  }
  return post<MyBuyListRes>('/api/v1/member/buy/record', {
    body: JSON.stringify(params)
  })
}

/**
 * 积分使用记录
 * @returns UserProfile
 */

export const getMyExchangeRecordList = async (
  details: PageReq
): Promise<any> => {
  const params = {
    ...details,
    ...getBaseReq()
  }
  return post<any>('/api/v1/member/integral/exchange/record', {
    body: JSON.stringify(params)
  })
}

/**
 * VIP邀请码兑换
 * @returns UserProfile
 */

export const getMyInviteChange = async (
  inviteCode: string
): Promise<UserProfile> => {
  const params = {
    invite_code: inviteCode,
    ...getBaseReq()
  }
  return post<UserProfile>('/api/v1/member/invite/exchange', {
    body: JSON.stringify(params)
  })
}

/**
 * 修改用户信息
 * @returns UserProfile
 */

export const getUpdateInfo = async ({
  param,
  type
}: {
  param: string
  type: number
}): Promise<string> => {
  const params = {
    ...getBaseReq(),
    param,
    type
  }
  return post<string>('/api/v1/member/update/info', {
    body: JSON.stringify(params)
  })
}

/**
 * 官网交流群
 * @returns UserProfile
 */
export const getMyOfficeGroup = async (): Promise<any> => {
  const params = {
    ...getBaseReq()
  }
  return post<UserProfile>('/api/v1/member/official/group', {
    body: JSON.stringify(params)
  })
}

/**
 * 官网交流群
 * @returns UserProfile
 */

export const getAccountCredentials = async ({
  uid,
  token
}: {
  uid: number
  token: string
}): Promise<AccountCredentialsRes> => {
  return post<AccountCredentialsRes>('/api/v1/member/account/credentials', {
    body: JSON.stringify({ uid, token })
  })
}
