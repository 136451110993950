// 涉及原生交互处理工具函数

// import { getAdvPostClick } from '@/api/common'
import { Advertise } from '@/interfaces/common'
import { JUMP_TYPE } from '@/constants/common'
import { NavigateFunction } from 'react-router-dom'
import { getAdvPostClick } from '@/api/common'

// 支持的设备的类型
export type PlatformInfo = 'android' | 'ios' | 'h5'
export type AdvertiseKeys = keyof Advertise

export const platformInUrl = {
  android: 'android_url',
  ios: 'ios_url',
  h5: 'h5_url'
} as {
  [key in PlatformInfo]: AdvertiseKeys
}

// 路由跳转
//1、url 跳转地址
//2、type 跳转类型,1、内部跳转，2、外部跳转
export const handleNativeRouteJump = (
  item: Advertise,
  router: NavigateFunction,
  options?: any
) => {
  options?.setAdvPostClick !== false && getAdvPostClick(item.id)
  options?.onClick?.(item)
  const platform = platformInUrl[getNativePlatform()]
  // 如果 嵌套在 壳子  优先执行 壳子提供的  native
  if (item.jump === JUMP_TYPE.EXTERNAL && window?.native?.openUrl) {
    const newUrl = /(http|https):\/\/([\w.]+\/?)\S*/.test(
      item[platform].toString()
    )
      ? item[platform]
      : `${window.location.origin}/#${item[platform]}`

    window.native.openUrl(newUrl, 2)
    return
  }

  // 如果不是壳子 采用原来的判断
  if (item.jump === JUMP_TYPE.EXTERNAL) {
    const newUrl =
      item[platform].toString().includes('http') ||
      item[platform].toString().includes('https')
        ? item[platform]
        : `${window.location.origin}/#${item[platform]}`
    window.open(`${newUrl}`, '_blank')
    // window.open(`${'http:www.baidu.com'}`)
    return
  } else if (item.jump === JUMP_TYPE.INTERNAL) {
    // 内部跳转也需要处理
    options?.onCloseAdv?.(item)
    router(`${item[platform]}`)
    return
  }
}

/**
 * 获取允许的平台
 * 获取平台：Android ，IOS，H5
 * @returns android ｜ ios ｜ h5
 */
export function getNativePlatform(): PlatformInfo {
  // 这一行是ios 注入的
  let platform = (window?.native?.platform as PlatformInfo) || ''
  if (platform) {
    return platform
  }
  if (!platform && window?.native?.getPlatform) {
    // 这一行android注入的
    platform = window.native.getPlatform?.() as PlatformInfo
    return platform
  }
  return 'h5'
}

/**
 * 获取渠道
 * @returns string
 */
export function getChannelCode(): string {
  let channelCode = window?.native?.channelCode || ''
  if (channelCode) {
    return channelCode
  }
  if (!channelCode && window?.native?.getChannelCode) {
    channelCode = window.native.getChannelCode?.() as string
    return channelCode || ''
  }
  return channelCode
}

/**
 * 获取顶部安全距离
 * @returns string
 */
export function getStatusBarHeight(): string {
  let barHeight = window?.native?.statusBarHeight || ''
  if (barHeight) {
    return barHeight
  }
  if (!barHeight && window?.native?.getStatusBarHeight) {
    barHeight = window.native.getStatusBarHeight?.() as string
    return barHeight || ''
  }
  return barHeight
}
