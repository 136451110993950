import { getToken } from '@/utils/session'
import info from '../../../package.json'

export function commonHeaders() {
  const headers = {
    CLIENT: info?.name.split('-').pop()
  } as Record<string, any>

  const strToken = getToken()
  if (strToken) {
    headers['API-TOKEN'] = strToken
  }
  return headers
}
