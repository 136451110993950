import CryptoJS from 'crypto-js'

// 生成128位密钥和初始化IV
const key = CryptoJS.enc.Utf8.parse('qrRCjxruBaInnSFv')
const iv = CryptoJS.enc.Utf8.parse('S58LowsUeDkyX4KT')

// 加密方法
const encrypt = (text: string) => {
  const textHex = CryptoJS.enc.Utf8.parse(text)
  const encrypted = CryptoJS.AES.encrypt(textHex, key, {
    iv: iv
  })
  return encrypted.ciphertext?.toString(CryptoJS.enc.Base64)
}

// 解密方法
const decrypt = (text: string) => {
  const decrypted = CryptoJS.AES.decrypt(text, key, {
    iv: iv
  })
  return CryptoJS.enc.Utf8.stringify(decrypted)
}

/**
 * 图片解密
 * @param url
 * @returns base64
 */
export const getDecryptImage = async (url: string): Promise<string> => {
  try {
    const response = await fetch(url)
    const arrayBuffer = await response.arrayBuffer() // 获取二进制数据
    const byteArray = new Uint8Array(arrayBuffer)
    // 2. 用Xor解密，种子为 88
    const seed = 88
    for (let i = 0; i < byteArray.length; i++) {
      byteArray[i] ^= seed // XOR操作
    }

    const getImageType = url.substring(url.lastIndexOf('.') + 1)
    // 3. 将解密后的数据转换为Blob，并进行渲染
    const blob = new Blob([byteArray], {
      type: `image/${getImageType || 'png'}`
    })

    return Promise.resolve(URL.createObjectURL(blob))
  } catch (error) {
    console.info(error)
    return Promise.reject('')
  }
}

export { decrypt, encrypt }
