import {
  AjaxCancelError,
  BusinessError,
  ConnectionError,
  isPlainObj,
  trimEnd,
  trimStart
} from 'dcjiayun-js'
import { AbortController } from 'node-abort-controller'
import { commonHeaders } from './commonHeaders'
import { errorHandler } from './errorHandler'
import { isBrowser } from '@/utils'
import { encrypt, decrypt } from '../aes'
import info from '../../../package.json'
import toast from '@/components/toast'
import { getNativePlatform } from '../native'
export interface ResponseData<TData = any> {
  code: number
  data: TData
  message: string
  msg: string
}
function isFormData(v: any) {
  return Object.prototype.toString.call(v) === '[object FormData]'
}

function withBaseUrl(url: string): string {
  const separator = '/'
  if (/^https?/.test(url)) {
    return url
  } else {
    return [
      trimEnd(isBrowser() ? '' : process.env.NEXT_PUBLIC_SERVER_API, separator),
      separator,
      trimStart(url, separator)
    ].join('')
  }
}

type FetchOptions = {
  url: string
  timeout?: number
  autoToast?: boolean
  query?: any
} & RequestInit

export function formatReqOptions(json?: string): string {
  const baseOptions = {
    timestamp: new Date().getTime(),
    version: info?.version,
    os: getNativePlatform()
  }
  try {
    const data = JSON.parse(json || '{}')
    return JSON.stringify({ ...baseOptions, ...data })
  } catch {
    return JSON.stringify(baseOptions)
  }
}

export function normalizeFetchOptions(
  params: string | FetchOptions
): FetchOptions {
  let _url: string
  let _params: RequestInit & { query?: any }
  if (typeof params === 'string') {
    _url = withBaseUrl(params)
    _params = {
      headers: commonHeaders()
    }
  } else {
    const { url, ...rest } = params
    if (rest.headers instanceof Headers) {
      const customHeaders = {} as Record<string, any>
      rest.headers.forEach((v, k) => (customHeaders[k] = v))
      rest.headers = Object.assign(commonHeaders(), customHeaders)
    } else {
      rest.headers = Object.assign(commonHeaders(), rest.headers)
    }
    _url = withBaseUrl(url)
    _params = { ...rest }
  }
  if (
    _params.headers &&
    (isPlainObj(_params.body) ||
      /^{/i.test(_params.body as string) ||
      !_params.body)
  ) {
    // 设置默认content-type为json
    // @ts-ignore
    _params.headers['Content-Type'] = 'application/json'
  }

  if (_params.method === 'get' && _params.query) {
    const esc = encodeURIComponent
    const newQuery: string[] = []
    for (const k of Object.keys(_params.query)) {
      if (_params.query[k] !== void 0) {
        newQuery.push(esc(k) + '=' + esc(_params.query[k]))
      }
    }
    const queryParams = newQuery.length > 0 ? newQuery.join('&') : ''
    if (queryParams) {
      _url =
        _url.indexOf('?') === -1
          ? `${_url}?${queryParams}`
          : `${_url}&${queryParams}`
      delete _params.query
    }
  } else if (!isFormData(_params.body)) {
    const data = encrypt(formatReqOptions(_params.body?.toString()))
    _params.body = JSON.stringify({
      data
    })
  }

  return {
    url: _url,
    ..._params
  }
}

/**
 * fetch代理函数
 * 用来接管共用异常处理逻辑
 */
export function fetchProxy<TResponse = Response | ResponseData>(
  params: string | FetchOptions
): Promise<TResponse> {
  const {
    url: _url,
    autoToast = true,
    ..._params
  } = normalizeFetchOptions(params)

  // 请求超时处理-服务端8s
  let timeout = isBrowser() ? 100000 : 8000
  let isTimeout = false
  if (typeof params === 'object') {
    timeout = params.timeout || timeout
  }
  const controller = new AbortController()
  const ajaxTimeoutId = setTimeout(() => {
    isTimeout = true
    controller.abort()
  }, timeout)
  ;(_params as any).signal = _params.signal || controller.signal

  return fetch(_url, _params)
    .then<any>((response: Response) => {
      if (!response.ok) {
        throw new ConnectionError({
          status: response.status,
          message: response.statusText
        })
      }
      const contentType = response.headers.get('content-type')
      if (contentType?.includes('application/json')) {
        return response.json().then((data: ResponseData) => {
          if (data.code === 200) {
            return JSON.parse(decrypt(data.data))
          }
          if (autoToast) {
            toast((data as any).msg)
          }
          return Promise.reject(
            new BusinessError(data.code, data.message || data.msg || '', data)
          )
        })
      }
      return Promise.resolve(response)
    })
    .catch((error) => {
      if (error.name && error.name.toLowerCase().includes('abort')) {
        error = isTimeout
          ? new ConnectionError({ status: 0, message: 'timeout' }) // 超时异常
          : new AjaxCancelError() // 请求被取消异常
      }
      let isNeedReplace = false
      const abnormalErrors = ['Acceptable', 'Failed', '发生了SSL错误']
      abnormalErrors.forEach((item) => {
        if (
          (error.message || '').toLowerCase().indexOf(item.toLowerCase()) !== -1
        ) {
          isNeedReplace = true
        }
      })
      if (isNeedReplace) {
        error = new Error('您的网络异常')
      }
      errorHandler(error)
      return Promise.reject(error)
    })
    .finally(() => {
      clearTimeout(ajaxTimeoutId)
    })
}

export function get<TResponse>(
  url: string,
  options: Omit<FetchOptions, 'url'> = {}
) {
  return fetchProxy<TResponse>({ url, ...options, method: 'get' })
}

export function post<TResponse>(
  url: string,
  options: Omit<FetchOptions, 'url'> = {}
) {
  return fetchProxy<TResponse>({ url, ...options, method: 'post' })
}

export function put<TResponse>(
  url: string,
  options: Omit<FetchOptions, 'url'> = {}
) {
  return fetchProxy<TResponse>({ url, ...options, method: 'put' })
}

export function del<TResponse>(
  url: string,
  options: Omit<FetchOptions, 'url'> = {}
) {
  return fetchProxy<TResponse>({ url, ...options, method: 'delete' })
}
