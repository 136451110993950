import { v4 as uuidv4 } from 'uuid'
import { encrypt, decrypt } from './aes'
export const encryptUUID = encrypt('_uuid')
export const readUUID = () => {
  return (
    decrypt(localStorage.getItem(encryptUUID) || '') ||
    (() => {
      // 先获取存储的 uuid-主要针对ios 提前获取的情况
      let uuid = window?.native?.deviceId || ''
      console.info('ios uuid：', uuid)
      // 获取设备号，如果有壳子注入用剋在的
      if (!uuid && window?.native?.getDeviceId) {
        uuid = window.native.getDeviceId()
      } else if (!uuid) {
        uuid = uuidv4()
      }
      const encryptId = encrypt(uuid)
      try {
        localStorage.setItem(encryptUUID, encryptId)
      } catch (e) {
        console.error(e)
      }
      console.info('最终提供给接口 uuid：', uuid)
      // 将数据返回
      return uuid
    })()
  )
}
