import { useRequest } from 'ahooks';
import { getGlobalConfig } from '@/api/common';
import { CommonConfigRes } from '@/interfaces/common';
import { isEmpty } from 'lodash';
/**
 */
interface Ops {
  manual?: boolean;
  initialData?: CommonConfigRes;
  onSuccess?: (data: any, params: any) => void;
}
/**
 * 获取全局通用配置数据
 * @param ops
 * @returns
 */
export function useGlobalConfig(ops?: Ops) {
  const manual = Boolean(ops?.manual);
  const initialData = isEmpty(ops?.initialData)
    ? cacheGlobalConfig()
    : ops?.initialData;
  const {
    data = initialData,
    loading,
    error,
    run,
    runAsync,
    mutate
  } = useRequest(getGlobalConfig, {
    // useRequest 存在内存刷新就没了
    cacheKey: 'cacheKey_global_config',
    // 用传入的数控控制是否调用一次接口，
    manual: manual || !isEmpty(ops?.initialData),
    onSuccess: (data, params)=>{
      ops?.onSuccess?.(data, params)
    }
  });

  if (data) cacheGlobalConfig(data);

  return { data, loading, error, refresh: run, runAsync, mutate };
}

const _GlobalConfigCacheKey = 'useGlobalConfigKey';
// 内存缓存不是特殊场景不要大面积使用-全局配置数据是所有用户通用数据可以使用
export function cacheGlobalConfig(
  data?: CommonConfigRes
): CommonConfigRes | undefined {
  if (data) {
    window.localStorage.setItem(_GlobalConfigCacheKey, JSON.stringify(data));
  } else {
    return JSON.parse(window.localStorage.getItem(_GlobalConfigCacheKey) || "{}");

  }
}
